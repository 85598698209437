.authPopup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  background-color: white;
}
.authPopup .pattern-right {
  position: absolute;
  left: 0;
  height: 100vh;
}
.authPopup .pattern-left {
  position: absolute;
  right: 0;
  height: 100vh;
}
.authPopup .auth-popup-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 48px;
  flex-wrap: wrap;
  width: 90vw;
  background-color: white;
  padding: 56px 96px;
  z-index: 2;
}
.authPopup .form-content {
  flex: 1 1 300px;
}
.authPopup .form-content .form-text,
.authPopup .form-content .form-container {
  flex: 1 1 300px;
}
.authPopup .form-content .form-text .welcome-text {
  display: grid;
  height: -moz-fit-content;
  height: fit-content;
  gap: 24px;
}
.authPopup .form-content .form-text .welcome-title {
  background-color: #ECF4F7;
  padding: 6px 25px;
  border-radius: 6px;
  text-align: center;
  font-size: 14px;
  font-weight: normal;
  text-transform: uppercase;
  color: #145C74;
  width: -moz-fit-content;
  width: fit-content;
}
.authPopup .form-content .form-text .title {
  text-transform: capitalize;
  font-size: 34px;
  line-height: 34px;
}
.authPopup .form-content .form-text .title span {
  color: #07A1AF;
}
.authPopup .auth-form {
  flex: 1 1 300px;
  padding: 24px;
  border: 1px solid #EDECEC;
  border-radius: 8px;
}
.authPopup .auth-form .form-container {
  display: grid;
  gap: 24px;
  height: -moz-fit-content;
  height: fit-content;
}
.authPopup .auth-form .form-container .form-title {
  font-size: 24px;
  color: #145C74;
  font-weight: bold;
}
.authPopup .auth-form .form-container .social-buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 24px;
  flex-wrap: wrap;
}
.authPopup .auth-form .form-container .social-buttons .social-button {
  flex: 1 1 200px;
  background-color: #ECF4F7;
  border-radius: 12px;
  padding: 12px;
  display: flex;
  align-items: center;
  gap: 12px;
  justify-content: center;
}
.authPopup form {
  display: grid;
  gap: 16px;
}
.authPopup form button {
  text-align: center;
  justify-content: center;
  width: 100%;
}
.authPopup .form-links {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.authPopup .form-links .form-link {
  color: #145C74;
  cursor: pointer;
}
.authPopup input {
  padding: 12px 16px;
  color: inherit;
  font-size: 16px;
  border-radius: 8px;
  outline: none;
  border: none;
  background-color: #F7F7F7;
}
.authPopup input::-moz-placeholder {
  font-size: 16px;
  color: #A9A9A9;
}
.authPopup input::placeholder {
  font-size: 16px;
  color: #A9A9A9;
}

.loading-icon {
  animation: loadingIcon linear 1s infinite;
}

@keyframes loadingIcon {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@media screen and (max-width: 720px) {
  .authPopup {
    overflow: scroll;
  }
  .authPopup .pattern-left {
    display: none;
  }
  .authPopup .pattern-right {
    display: none;
  }
  .authPopup .auth-popup-content {
    gap: 24px;
    padding: 0;
  }
  .authPopup .form-content .form-text {
    display: none;
  }
  .authPopup .form-content .form-text .welcome-text {
    gap: 12px;
  }
  .authPopup .form-content .form-text .welcome-title {
    font-size: 12px;
  }
  .authPopup .form-content .form-text .title {
    font-size: 18px;
  }
  .authPopup .auth-form .form-container .form-title {
    font-size: 18px;
    font-weight: bold;
  }
  .authPopup .auth-form .form-container .social-buttons {
    gap: 12px;
  }
  .authPopup .auth-form .form-container .social-buttons .social-button {
    font-size: 14px;
  }
  .authPopup .form-links {
    align-items: start;
    font-size: smaller;
  }
}/*# sourceMappingURL=forms.css.map */