@import url("https://fonts.googleapis.com/css2?family=Kanit:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
.footer {
  background-color: #145C74;
  margin-top: 80px;
  background-image: url("/public/images/footer/footer_cover.svg");
  height: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 80px;
  padding-inline: 100px;
  padding-bottom: 30px;
  width: 100%;
}
.footer .info-content {
  background-image: url("/public//images/footer/Image.png");
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  border-radius: 20px;
  overflow: hidden;
  background-size: cover;
}

.text-info {
  display: flex;
  padding-block: 50px;
  gap: 100px;
  justify-content: center;
}

.footer-text {
  display: flex;
  align-items: center;
  gap: 30px;
}
.footer-text .header-address {
  display: flex;
  flex-direction: column;
  gap: 7px;
}
.footer-text .header-address h4 {
  color: white;
  font-size: 18px;
  font-weight: 800;
}
.footer-text .header-address h5 {
  color: white;
  font-size: 14px;
  font-weight: 500;
  font-family: "Kanit", sans-serif;
}
.footer-text .border-icon {
  border: 1px solid rgba(255, 255, 255, 0.5);
  background-color: transparent;
  padding: 11px;
  border-radius: 999px;
  width: 63px;
  height: 63px;
}
.footer-text .footer-icon {
  background-color: white;
  border-radius: 999px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.bottom-footer {
  display: flex;
  padding-top: 20px;
  gap: 200px;
  padding-bottom: 20px;
}
.bottom-footer .different-links {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 120px;
}
.bottom-footer .different-links .useful-links {
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.bottom-footer .different-links .useful-links h1 {
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
  font-family: "Kanit", sans-serif;
  color: white;
}
.bottom-footer .different-links .useful-links .tag-text {
  display: flex;
  gap: 8px;
  align-items: center;
}
.bottom-footer .different-links .useful-links .tag-text p {
  font-size: 16px;
  color: #F2F2F2;
}
.bottom-footer .social-footer {
  display: flex;
  gap: 10px;
}
.bottom-footer .social-footer .social-border {
  border: 1px solid white;
  border-radius: 999px;
  width: 40px;
  height: 40px;
  align-items: center;
  display: flex;
}
.bottom-footer .social-footer .social-border .icon5 {
  display: flex;
  justify-content: center;
  text-align: center;
  padding-left: 10px;
}
.bottom-footer .logo-text {
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.bottom-footer .logo-text p {
  color: white;
  font-size: 18px;
  line-height: 28px;
  font-weight: 400;
}

.copy-right {
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 30px;
}
.copy-right p {
  text-align: center;
  color: white;
  font-weight: 400;
  font-size: 14px;
}
.copy-right hr {
  border: 1px solid rgba(233, 233, 233, 0.2);
}

@media screen and (max-width: 1500px) {
  .bottom-footer .logo-text p br {
    display: none;
  }
}
@media screen and (max-width: 1260px) {
  .text-info {
    gap: 70px;
  }
  .bottom-footer {
    gap: 100px;
  }
  .bottom-footer .logo-text p br {
    display: none;
  }
}
@media screen and (max-width: 1180px) {
  .text-info {
    gap: 30px;
  }
}
@media screen and (max-width: 1080px) {
  .text-info {
    scale: 0.8;
  }
  .bottom-footer .logo-text {
    width: 50%;
  }
  .bottom-footer .different-links {
    gap: 20px;
    grid-template-columns: 1fr 1fr;
  }
}
@media screen and (max-width: 980px) {
  .footer {
    padding-inline: 70px;
  }
  .text-info {
    padding-block: 20px;
  }
}
@media screen and (max-width: 850px) {
  .text-info {
    scale: 0.7;
    gap: 40px;
  }
}
@media screen and (max-width: 780px) {
  .footer {
    padding-inline: 40px;
  }
  .text-info {
    gap: 60px;
  }
}
@media screen and (max-width: 680px) {
  .footer {
    padding-inline: 20px;
  }
}
@media screen and (max-width: 650px) {
  .footer {
    padding-inline: 0px;
  }
  .footer .bottom-footer {
    padding-inline: 10px;
    flex-direction: column;
    gap: 40px;
    padding-top: 30px;
  }
  .footer .bottom-footer .logo-text {
    width: 100%;
  }
  .footer .bottom-footer .different-links {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
@media (max-width: 600px) {
  .text-info {
    scale: 0.6;
    gap: 70px;
  }
}
@media (max-width: 520px) {
  .text-info {
    scale: 0.5;
  }
}
@media (max-width: 500px) {
  .footer .info-content {
    height: 200px;
  }
  .footer .bottom-footer .different-links {
    grid-template-columns: 30% 40%;
  }
  .text-info {
    flex-direction: column;
    justify-content: center;
    gap: 10px;
    position: relative;
    top: -40px;
    scale: 0.7;
  }
}/*# sourceMappingURL=footer.css.map */