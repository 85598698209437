.applications-page {
    .container {
        margin: auto;
        padding: 50px 0;

        .applications-placeholder {
            display: grid;
            gap: 24px;
        }

        .title {
            display: flex;
            align-items: center;
            gap: 12px;
        }

        .applications-count {
            height: 24px;
            width: 24px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 12px;
            color: #145C74;
        }

        .applications {
            display: grid;
            gap: 24px;

            .actions-filters {
                display: flex;
                align-items: center;
                justify-content: space-between;

                .search-input {
                    display: flex;
                    align-items: center;
                    gap: 12px;
                    border-radius: 12px;
                    border: 1px solid whitesmoke;
                    padding-right: 4px;

                    input {
                        border: 0;
                        width: 400px;
                    }

                    button {
                        padding: 8px 12px;
                    }
                }

                .filters {
                    display: flex;
                    align-items: center;
                    gap: 12px;
                }
            }
        }
    }
}

table {
    width: 100%;
    border-collapse: collapse;
    border-radius: 12px;

    th,
    td {
        border-bottom: 1px solid #ccc;
        ;
        padding: 10px;
        text-align: start;
    }

    thead {
        tr {
            background-color: #145C74;
            color: white;
            border-radius: 12px;
        }
    }

    tbody {
        font-weight: 300;

        tr:nth-child(even) {
            background-color: #f2f2f2;
        }
    }
}