// put your css here
@import '../variables/variables.scss';
@import url('https://fonts.googleapis.com/css2?family=Lexend:wght@100..900&family=Outfit:wght@100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lexend:wght@100..900&display=swap');


* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: "Lexend", sans-serif;
  scroll-behavior: smooth;
}

input[type="checkbox"] {
  width: fit-content
}

.check-box {
  display: flex;
  align-items: center;
  gap: 6px;
}

.text-header {
  background-color: #E5F8FF;
  border-radius: 12px;
  color: $primary-color;
  display: flex;
  gap: 10px;
  width: fit-content;
  padding: 12px;
  padding-inline: 20px;
  height: 44px;



  svg {
    color: $primary-color;
  }

  h2 {
    font-size: 16px;
    font-weight: 600;
    font-family: "Outfit", sans-serif;
  }
}

.button-icon {
  display: flex;
  align-items: center;
  border-radius: 32px;
  padding: 8px;
  color: $primary-color;
  border: 2px solid $primary-color;
  background-color: transparent;
  width: 206px;
  height: 54px;
  justify-content: space-between;
  padding-left: 30px;


  h6 {
    font-size: 18px;
    font-weight: 400;
  }

  .header-icon {
    background-color: $primary-color;
    border-radius: 50%;
    text-align: center;
    width: 38px;
    height: 38px;
    display: flex;
    justify-content: center;
    align-items: center;



    svg {

      color: white;
      text-align: center;
    }

  }


}

a {
  text-decoration: none;

}

.loading-container {
  height: 100vh;
  width: 100vw;
  overflow: hidden !important;

  .card {
    height: 50px;
    width: 150px;
    background: linear-gradient(to right, #e1e1e1, #dbdbdb68);
    background-size: 400% 100%;
    animation: gradient-slide 0.8s linear infinite;
  }
}

@keyframes gradient-slide {
  0% {
    background-position: 0% 0;
  }

  100% {
    background-position: -100% 0;
  }
}

.profile-loading-container,
.user-profile-page {
  display: grid;
  gap: 24px;
  height: fit-content;

  height: 100vh;
  width: 100vw;
  overflow: hidden !important;

  .profile-container {
    background-color: #F6F6F6;
    display: grid;
    gap: 24px;
    padding: 24px;

    .title {
      .card {
        width: 388px;
        height: 41px;
      }
    }

    .profile-info {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      gap: 24px;

      .profile-pic {
        .card {
          width: 98px;
          height: 98px;
        }
      }

      .names-role,
      .email-phone-number {
        flex: 1 1 200;
        height: 68;

        .card {
          height: 98px;
        }
      }

      .names-role {
        display: grid;
        height: fit-content;
        gap: 6px;

        .card {
          height: 30px;
        }
      }

      .email-phone-number {
        flex: 1;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        gap: 24px;

        .card {
          flex: 1 1 200px;
          max-width: 200px;
        }
      }
    }
  }

  .courses-section {
    padding: 24px;
    background-color: #F6F6F6;

    .courses-container {
      display: grid;
      height: fit-content;
      gap: 24px;

      .courses-grid {
        display: flex;
        flex-wrap: wrap;
        gap: 12px;

        .course {
          flex: 1 1 280px;
          height: 180px;
        }
      }
    }


  }
}

form {
  display: grid;
  gap: 24px;
  height: fit-content;

  .half {
    display: flex;
    align-items: center;
    gap: 12px;
    flex-wrap: wrap;

    .field {
      flex: 1 1 280px
    }
  }
}

input,
select,
textarea {
  padding: 16px 24px;
  width: 100%;
  font-size: 14px;
  outline: none;
  border: 1px solid #eee;
  border-radius: 8px;
}

.field {
  display: grid;
  gap: 6px;
}

@media screen and (max-width: 768px) {
  .profile-loading-container {
    .profile-container {
      .title {
        .card {
          width: 50%;
        }
      }

      .profile-info {
        .profile-pic {
          .card {
            height: 72px;
            width: 72px;
          }
        }

        .email-phone-number {
          .card {
            max-width: 100%;
            height: 48px;
          }
        }
      }
    }
  }
}

.custom-select-input {
  position: relative;

  .options-container {
    background-color: whitesmoke;
    width: 100%;
    padding: 12px;
    border-radius: 10px;
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    z-index: 4;
  }

  .options {
    display: grid;
    height: fit-content;

    .option {
      padding: 6px;
      border-bottom: 1px solid rgb(231, 231, 231);

      &:last-child {
        border-bottom: none;
      }

      &:hover {
        background-color: $secondary-color;
        border-color: transparent;
      }

      &:first-child {
        color: gray;

        &:hover {
          background-color: transparent;
        }
      }
    }
  }
}

.custom-input {
  position: relative;

  .icon {
    position: absolute;
    right: 16px;
    top: 14px;
    color: inherit;
  }
}