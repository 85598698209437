@import '../variables/variables.scss';
.line{
  display: flex;
}
.radio{
  display: flex;
  align-items: center;
  gap: 20px;
}

.proceed{
  display: flex;
  gap: 30px;
}

.third-form{
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-top: 30px;

  h1{
    font-size: 23px;
  }

  .payment-text{
    background-color: rgba(0, 115, 255, 0.173);
    padding: 25px;
    border-radius: 10px;
    margin-block: 6px;
  }

  .mobile{
    border: 1px solid #EDEDED;
    padding: 15px;
    border-radius: 10px;
  }
}
.button-icon{
  cursor: pointer;
}
.how-to-apply{
  margin-top: 70px;
  .apply-content{
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    gap: 20px;

    h1{
      font-size: 40px;
      font-weight: 700;
      line-height: 50px;
      color: $primary-color;
    }

    p{
      font-size: 24px;
      color: $secondary-color;
      line-height: 28px;
    }
  }

  .registration-form{
    display: grid;
    justify-content: center;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 50px;
    margin-top: 60px;


   

    .registration-item{
      display: flex;
      flex-direction: column;
      gap: 50px;
      background-color: #FBFBFB;
      border-radius: 40px;
      padding: 40px;
      padding-top: 20px;
      align-items: center;

      h1{
        font-size: 22px;
        font-weight: 400;
        color: #2D2D2D;
        text-align: center;
      }

      .regis-icon{
        background-color: white;
       
        width: 200px;
        height: 80px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 10px;
      }
    }
  }
}

.payment-icon{
  border: 1.8px solid black;
  width: 24px;
  height: 24px;
  border-radius: 50px;
  align-items: center;
  display: flex;
  justify-content: center;
  cursor: pointer;
  margin-left: auto;

}
// SCSS for the popup form overlay
.popup-form {
  position: fixed;
  
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10; 
  
}

.popup-content {
  position: fixed;
  z-index: 100;
  top: 50%;
  margin-top: 50px;
  left: 50%;
  transform: translate(-50%, -50%);
  // max-width: 750px;
  // max-height: 700px;
  height: fit-content;
  width: fit-content;
  background-color: white;
  padding: 30px;
  border-radius: 24px;

  h3 {
      margin-top: 0;
      font-size: 18px;
      font-weight: 700;
  }
  span{
    color: $primary-color;
  }

  .header-popup{
    display: flex;
    flex-direction: column;
    // justify-content: center;
    // align-items: center;
    // text-align: center;
    text-align: left;
    gap: 15px;
    padding-bottom: 20px;
    padding-top: 30px;

    h2{
      font-size: 16px;
      font-weight: 700;
    }
    h3{
      font-size: 16px;
      font-weight: 600;
      span{
        color: $primary-color;
      }
    }
  }

  .second-form{
   margin-top: 60px;
  }

  .second-buttons{
    display: flex;
    gap: 10px;
    margin-top: 60px;
  }

  .application-form{
    display: flex;
    align-items: center;
    position: relative;
    
  
  }
 .popup-buttons{
  display: flex;
  gap: 10px;
  justify-content: flex-end;
 }
  form {
     
    select{
      color: black !important;
    }

      .form-elements{
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 20px;
      }
    

      input[type="text"],
      input[type="email"],
      select {
          width: 100%;
          padding: 16px;
          margin: 10px 0;
          border: none;
          border-radius: 8px;
          background-color: #F8F8F8;
          box-sizing: border-box;

          color: black;
          font-size: 16px;
      }
  }

  label{
    color: #858585;
          font-size: 16px;
  }

   .submitted{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-top: 40px;
    gap: 20px;

    .submitted-icon{
      width: 96.52px;
      height: 96.52px;
      background-color: $primary-color;
      border-radius: 50%;
      align-items: center;
      display: flex;
      justify-content: center;
    }
    .icon-border{
      width: 140px;
      height: 140px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      border: 8px solid #07A1AF14;
    }

    h2{
      font-size: 20px;
      color: $primary-color;
    }
    p{
      color: $secondary-color;
      font-size: 20px;
    }
    .submitted-button{
      width: fit-content;
      height: 46px;
      font-size: 16px;
      font-weight: 400;
     padding-left: 30px;

    }
   }

  button {
      background-color: $primary-color;
      color: white;
      border: none;
      cursor: pointer;
      border-radius: 8px;
      // padding: 12px;
      // cursor: pointer;
      margin-top: 10px;
      max-width: 163px;
      width: 100%;
      height: 100%;
      max-height: 46px;
      text-align: center;


      &.close  {
          background-color: #F0F0F0;
          color: #8D8E90;
         

          &:hover,
          &:focus {
              background-color: $primary-color;
          }
      }

      &.next  {
       

        &:hover,
        &:focus {
            background-color: black;
        }
    }
  }
}





@media screen and (max-width: 1300px) {
    .how-to-apply{
      .registration-form{
        .registration-item{
          h1{
            br{
              display: block;
            }
          }
        }
      }
    }
}


@media screen and (max-width: 1340px) {
  .how-to-apply{
    .registration-form{
     gap: 10px;
    }
  }
}

@media screen and (max-width: 1200px) {
  .how-to-apply{
    .registration-form{
      .registration-item{
       padding: 20px;
      }
    }
  }
}

@media screen and (max-width:1180px) {
  .how-to-apply{
    .apply-content{
      padding: 10px;
    }
  }
}

@media screen and (max-width:1080px) {
  .how-to-apply{

    .apply-content{
      padding: 20px;
      p{
        br{
          display: none;
        }
      }
    }
    .registration-form{
     display: grid;
     grid-template-columns: 40% 40%;
     gap: 50px;

      .registration-item{
        padding: 40px;
      }
    }
  }
}

@media screen and (max-width:980px) {
  .bottom-footer{
    gap: 30px;
  }
 
}

@media screen and (max-width:780px) {
  .how-to-apply{
   .registration-form{
    grid-template-columns: 40% 40%;
   }
  }

}

@media screen and (max-width:600px) {
  .how-to-apply{
   .registration-form{
    grid-template-columns: 100%;
    padding-inline: 40px;
   }
  }

}

@media screen and (max-width:765px) {
  .popup-content{
   .header-popup{
    h2{
      br{
        display: none;
      }
    }
   }
  }

}

@media (max-width: 550px) {
  .how-to-apply{
    .apply-content{
      h1{
        font-size: 30px;
        line-height: 40px;
      }
    }
  }
}