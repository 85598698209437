@import '../variables/variables';

.authPopup {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    background-color: white;

    .pattern-right {
        position: absolute;
        left: 0;
        height: 100vh;
    }

    .pattern-left {
        position: absolute;
        right: 0;
        height: 100vh;
    }

    .auth-popup-content {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 48px;
        flex-wrap: wrap;
        width: 90vw;
        background-color: white;
        padding: 56px 96px;
        z-index: 2;
    }

    .form-content {
        flex: 1 1 300px;

        .form-text,
        .form-container {
            flex: 1 1 300px;
        }

        .form-text {

            .welcome-text {
                display: grid;
                height: fit-content;
                gap: 24px;
            }

            .welcome-title {
                background-color: #ECF4F7;
                padding: 6px 25px;
                border-radius: 6px;
                text-align: center;
                font-size: 14px;
                font-weight: normal;
                text-transform: uppercase;
                color: $primary-color;
                width: fit-content;
            }

            .title {
                text-transform: capitalize;
                font-size: 34px;
                line-height: 34px;

                span {
                    color: #07A1AF;
                }
            }
        }
    }

    .auth-form {
        flex: 1 1 300px;
        padding: 24px;
        border: 1px solid #EDECEC;
        border-radius: 8px;

        .form-container {
            display: grid;
            gap: 24px;
            height: fit-content;

            .form-title {
                font-size: 24px;
                color: $primary-color;
                font-weight: bold;
            }

            .social-buttons {
                display: flex;
                align-items: center;
                justify-content: space-between;
                gap: 24px;
                flex-wrap: wrap;

                .social-button {
                    flex: 1 1 200px;
                    background-color: #ECF4F7;
                    border-radius: 12px;
                    padding: 12px;
                    display: flex;
                    align-items: center;
                    gap: 12px;
                    justify-content: center;
                }
            }
        }
    }

    form {
        display: grid;
        gap: 16px;

        button {
            text-align: center;
            justify-content: center;
            width: 100%;
        }
    }

    .form-links {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .form-link {
            color: $primary-color;
            cursor: pointer;
        }
    }

    input {
        padding: 12px 16px;
        color: inherit;
        font-size: 16px;
        border-radius: 8px;
        outline: none;
        border: none;
        background-color: #F7F7F7;

        &::placeholder {
            font-size: 16px;
            color: #A9A9A9;
        }
    }
}

.loading-icon {
    animation: loadingIcon linear 1s infinite;
}

@keyframes loadingIcon {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@media screen and (max-width: 720px) {
    .authPopup {
        overflow: scroll;

        .pattern-left {
            display: none;
        }

        .pattern-right {
            display: none;
        }

        .auth-popup-content {
            gap: 24px;
            padding: 0;
        }

        .form-content {
            .form-text {
                display: none;

                .welcome-text {
                    gap: 12px;
                }

                .welcome-title {
                    font-size: 12px;
                }

                .title {
                    font-size: 18px;
                }
            }
        }

        .auth-form {
            .form-container {
                .form-title {
                    font-size: 18px;
                    font-weight: bold;
                }

                .social-buttons {
                    gap: 12px;
                }

                .social-buttons {
                    .social-button {
                        font-size: 14px;
                    }
                }
            }
        }

        .form-links {
            align-items: start;
            font-size: smaller;
        }
    }
}