@import url("https://fonts.googleapis.com/css2?family=Lexend:wght@100..900&family=Outfit:wght@100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lexend:wght@100..900&display=swap");
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: "Lexend", sans-serif;
  scroll-behavior: smooth;
}

input[type=checkbox] {
  width: -moz-fit-content;
  width: fit-content;
}

.check-box {
  display: flex;
  align-items: center;
  gap: 6px;
}

.text-header {
  background-color: #E5F8FF;
  border-radius: 12px;
  color: #145C74;
  display: flex;
  gap: 10px;
  width: -moz-fit-content;
  width: fit-content;
  padding: 12px;
  padding-inline: 20px;
  height: 44px;
}
.text-header svg {
  color: #145C74;
}
.text-header h2 {
  font-size: 16px;
  font-weight: 600;
  font-family: "Outfit", sans-serif;
}

.button-icon {
  display: flex;
  align-items: center;
  border-radius: 32px;
  padding: 8px;
  color: #145C74;
  border: 2px solid #145C74;
  background-color: transparent;
  width: 206px;
  height: 54px;
  justify-content: space-between;
  padding-left: 30px;
}
.button-icon h6 {
  font-size: 18px;
  font-weight: 400;
}
.button-icon .header-icon {
  background-color: #145C74;
  border-radius: 50%;
  text-align: center;
  width: 38px;
  height: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.button-icon .header-icon svg {
  color: white;
  text-align: center;
}

a {
  text-decoration: none;
}

.loading-container {
  height: 100vh;
  width: 100vw;
  overflow: hidden !important;
}
.loading-container .card {
  height: 50px;
  width: 150px;
  background: linear-gradient(to right, #e1e1e1, rgba(219, 219, 219, 0.4078431373));
  background-size: 400% 100%;
  animation: gradient-slide 0.8s linear infinite;
}

@keyframes gradient-slide {
  0% {
    background-position: 0% 0;
  }
  100% {
    background-position: -100% 0;
  }
}
.profile-loading-container,
.user-profile-page {
  display: grid;
  gap: 24px;
  height: -moz-fit-content;
  height: fit-content;
  height: 100vh;
  width: 100vw;
  overflow: hidden !important;
}
.profile-loading-container .profile-container,
.user-profile-page .profile-container {
  background-color: #F6F6F6;
  display: grid;
  gap: 24px;
  padding: 24px;
}
.profile-loading-container .profile-container .title .card,
.user-profile-page .profile-container .title .card {
  width: 388px;
  height: 41px;
}
.profile-loading-container .profile-container .profile-info,
.user-profile-page .profile-container .profile-info {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 24px;
}
.profile-loading-container .profile-container .profile-info .profile-pic .card,
.user-profile-page .profile-container .profile-info .profile-pic .card {
  width: 98px;
  height: 98px;
}
.profile-loading-container .profile-container .profile-info .names-role,
.profile-loading-container .profile-container .profile-info .email-phone-number,
.user-profile-page .profile-container .profile-info .names-role,
.user-profile-page .profile-container .profile-info .email-phone-number {
  flex: 1 1 200;
  height: 68;
}
.profile-loading-container .profile-container .profile-info .names-role .card,
.profile-loading-container .profile-container .profile-info .email-phone-number .card,
.user-profile-page .profile-container .profile-info .names-role .card,
.user-profile-page .profile-container .profile-info .email-phone-number .card {
  height: 98px;
}
.profile-loading-container .profile-container .profile-info .names-role,
.user-profile-page .profile-container .profile-info .names-role {
  display: grid;
  height: -moz-fit-content;
  height: fit-content;
  gap: 6px;
}
.profile-loading-container .profile-container .profile-info .names-role .card,
.user-profile-page .profile-container .profile-info .names-role .card {
  height: 30px;
}
.profile-loading-container .profile-container .profile-info .email-phone-number,
.user-profile-page .profile-container .profile-info .email-phone-number {
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 24px;
}
.profile-loading-container .profile-container .profile-info .email-phone-number .card,
.user-profile-page .profile-container .profile-info .email-phone-number .card {
  flex: 1 1 200px;
  max-width: 200px;
}
.profile-loading-container .courses-section,
.user-profile-page .courses-section {
  padding: 24px;
  background-color: #F6F6F6;
}
.profile-loading-container .courses-section .courses-container,
.user-profile-page .courses-section .courses-container {
  display: grid;
  height: -moz-fit-content;
  height: fit-content;
  gap: 24px;
}
.profile-loading-container .courses-section .courses-container .courses-grid,
.user-profile-page .courses-section .courses-container .courses-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
}
.profile-loading-container .courses-section .courses-container .courses-grid .course,
.user-profile-page .courses-section .courses-container .courses-grid .course {
  flex: 1 1 280px;
  height: 180px;
}

form {
  display: grid;
  gap: 24px;
  height: -moz-fit-content;
  height: fit-content;
}
form .half {
  display: flex;
  align-items: center;
  gap: 12px;
  flex-wrap: wrap;
}
form .half .field {
  flex: 1 1 280px;
}

input,
select,
textarea {
  padding: 16px 24px;
  width: 100%;
  font-size: 14px;
  outline: none;
  border: 1px solid #eee;
  border-radius: 8px;
}

.field {
  display: grid;
  gap: 6px;
}

@media screen and (max-width: 768px) {
  .profile-loading-container .profile-container .title .card {
    width: 50%;
  }
  .profile-loading-container .profile-container .profile-info .profile-pic .card {
    height: 72px;
    width: 72px;
  }
  .profile-loading-container .profile-container .profile-info .email-phone-number .card {
    max-width: 100%;
    height: 48px;
  }
}
.custom-select-input {
  position: relative;
}
.custom-select-input .options-container {
  background-color: whitesmoke;
  width: 100%;
  padding: 12px;
  border-radius: 10px;
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  z-index: 4;
}
.custom-select-input .options {
  display: grid;
  height: -moz-fit-content;
  height: fit-content;
}
.custom-select-input .options .option {
  padding: 6px;
  border-bottom: 1px solid rgb(231, 231, 231);
}
.custom-select-input .options .option:last-child {
  border-bottom: none;
}
.custom-select-input .options .option:hover {
  background-color: #4F4F4F;
  border-color: transparent;
}
.custom-select-input .options .option:first-child {
  color: gray;
}
.custom-select-input .options .option:first-child:hover {
  background-color: transparent;
}

.custom-input {
  position: relative;
}
.custom-input .icon {
  position: absolute;
  right: 16px;
  top: 14px;
  color: inherit;
}/*# sourceMappingURL=main.css.map */