.page-text-img {
  display: flex;
  padding-top: 100px;
  align-items: flex-start;
  gap: 140px;
  padding-inline: 80px;
}
.page-text-img .page-text {
  display: flex;
  flex-direction: column;
  gap: 25px;
}
.page-text-img .page-text h1 {
  color: #145C74;
  font-size: 32px;
  font-weight: 700;
}
.page-text-img .page-text p {
  line-height: 28px;
  color: #4F4F4F;
  font-size: 18px;
}

.watch-video {
  padding-inline: 100px;
  padding-top: 100px;
}
.watch-video .video {
  border-radius: 24px;
  position: relative;
  overflow: hidden;
}
.watch-video .video .watch-vid {
  position: absolute;
  top: 65%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.watch-video .video h1 {
  color: white;
  font-size: 32px;
  font-weight: 600;
  text-align: center;
}
.watch-video .video img {
  width: 100%;
}
.watch-video .video .vid-icon {
  display: flex;
  justify-content: center;
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80.6px;
  height: 80.6px;
  border: 1.5px solid white;
  border-radius: 50px;
  padding-top: 25px;
}
.watch-video .video .vid-icon i {
  color: white;
  font-size: 30px;
}
.watch-video .video .background-image {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 99%;
  background-image: url("/public/images/footer/footer_cover.svg");
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

.service-header {
  width: 50%;
}

.services {
  padding-inline: 80px;
  padding-top: 80px;
}
.services h1 {
  color: #145C74;
  font-size: 24px;
  font-weight: 700px;
}
.services .service-starter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
}
.services .service-starter p {
  color: #4F4F4F;
  line-height: 28px;
  width: 80%;
}
.services h1 {
  margin-top: 15px;
}
.services .services-content {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  padding-top: 50px;
  gap: 20px;
  padding-bottom: 50px;
}
.services .services-content .service-text {
  display: flex;
  gap: 15px;
  align-items: center;
}
.services .services-content .service-text .ser-icon {
  background-color: #EAF9FF;
  padding: 10px;
  border-radius: 12px;
}
.services .highlighted-service {
  background-color: #F8FDFF;
  padding-block: 20px;
  padding-left: 2px;
  border-radius: 12px;
}

.training-center {
  background-color: #145C74;
  background-image: url("/public/images/footer/footer_cover.svg");
  padding-top: 80px;
  padding-inline: 80px;
  padding-bottom: 100px;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 100%;
  width: 100%;
}
.training-center .training-text {
  display: flex;
  padding-top: 40px;
  gap: 40px;
}
.training-center .training-text p {
  font-size: 24px;
  line-height: 34px;
  color: white;
}
.training-center .training-header {
  display: flex;
  flex-direction: column;
  gap: 30px;
}
.training-center .training-header h1 {
  font-size: 4vw;
  font-weight: 700;
  color: white;
}
.training-center .training-header h5 {
  font-size: 23px;
  font-family: Helvetica, sans-serif;
  color: white;
}

.testimonial {
  margin-top: 100px;
}
.testimonial .testimonial-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
}
.testimonial .testimonial-header h1 {
  font-size: 32px;
  font-weight: 400;
  color: #145C74;
}
.testimonial .testimonial-header h1 span {
  font-weight: 900;
}

.test-content {
  background-color: #F8F8F8;
  text-align: center;
  padding: 10px;
  padding-block: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  border-radius: 12px;
  margin-top: 50px;
}

.testimonial-text {
  display: flex;
  padding-left: 100px;
  gap: 30px;
  padding-top: 50px;
}

.test-icon i {
  font-size: 30px;
  color: #145C74;
}

.test-writings p {
  font-size: 16px;
  color: #4F4F4F;
  line-height: 28px;
}

h2 {
  font-size: 18px;
  color: #23242A;
  line-height: 28px;
}

.swiper-pagination {
  padding-top: 30px !important;
  position: static !important;
}

.partnership {
  margin-top: 60px;
  background-color: #145C74;
  background-image: url("/public/images/footer/patterns.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  display: flex;
  position: relative;
  overflow: hidden;
  padding: 60px;
  padding-inline: 90px;
  gap: 80px;
  width: 100%;
}
.partnership h1 {
  color: white;
  font-weight: 600;
  font-size: 52px;
  padding-top: 30px;
}
.partnership p {
  font-size: 18px;
  width: 400;
  color: white;
  line-height: 28px;
}
.partnership .know-more {
  background-color: white;
}
.partnership .partnership-text {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.yellow-vector {
  position: absolute;
  /* Adjust right positioning as needed */
  top: 0;
  right: 0;
  transform: translate(50%, -20%);
}
.yellow-vector img {
  width: 200px;
}

@media (max-width: 1440px) {
  .watch-video .video h1 {
    font-size: 25px;
  }
  .watch-video .video .vid-icon {
    width: 60px;
    height: 60px;
    align-items: center;
    padding-top: 0px;
  }
  .watch-video .video .vid-icon i {
    font-size: 30px;
  }
}
@media screen and (max-width: 1300px) {
  .page-text-img {
    gap: 100px;
  }
  .page-img img {
    width: 550px;
  }
  .training-img img {
    width: 550px;
  }
}
@media screen and (max-width: 1080px) {
  .page-text-img {
    flex-direction: column;
    gap: 60px;
  }
  .services {
    padding-inline: 50px;
  }
  .training-center .training-text {
    flex-direction: column;
  }
  .partnership {
    flex-direction: column;
    align-items: center;
  }
  .partnership p {
    text-align: center;
  }
  .partnership h1 {
    text-align: center;
  }
  .partnership .partnership-text {
    align-items: center;
  }
  .watch-video {
    padding-inline: 60px;
  }
  .testimonial .testimonial-text {
    padding-left: 60px;
  }
  .services .service-header {
    width: 50%;
  }
  .services .service-starter {
    align-items: flex-start;
    gap: 10px;
  }
  .services .service-starter p {
    width: 50%;
  }
  .services .service-starter p br {
    display: none;
  }
}
@media (max-width: 1060px) {
  .watch-video .video h1 {
    padding-top: 30px;
    font-size: 20px;
  }
}
@media (max-width: 850px) {
  .training-center {
    padding-inline: 60px;
  }
}
@media (max-width: 780px) {
  .watch-video {
    padding-inline: 30px;
  }
  .page-text-img {
    padding-top: 280px;
    padding-inline: 60px;
  }
  .training-center {
    padding-inline: 40px;
  }
  .partnership {
    padding-inline: 20px;
  }
  .services h1 br {
    display: none;
  }
  .services .service-header {
    width: 100%;
  }
  .services .service-starter {
    flex-direction: column;
    gap: 40px;
  }
  .services .service-starter p {
    width: 100%;
  }
}
@media (max-width: 650px) {
  .page-text-img {
    padding-inline: 20px;
  }
  .watch-video {
    padding-inline: 0px;
  }
  .training-center {
    padding-inline: 25px;
  }
  .training-img img {
    width: 100%;
  }
  .services {
    padding-inline: 30px;
  }
  .page-img img {
    width: 100%;
  }
  .part-img img {
    width: 100%;
  }
}
@media (max-width: 575px) {
  .watch-video .video h1 {
    font-size: px;
  }
  .watch-video .video .vid-icon {
    width: 50px;
    height: 50px;
  }
  .watch-video .video .vid-icon i {
    font-size: 22px;
  }
}
@media (max-width: 550px) {
  .services .services-content {
    grid-template-columns: 100%;
  }
}
@media (max-width: 485px) {
  .partnership {
    padding-inline: 10px;
  }
}
@media (max-width: 425px) {
  .watch-video .video h1 {
    font-size: 15px;
    padding-top: 10px;
  }
  .watch-video .video .vid-icon {
    width: 30px;
    height: 30px;
    top: 30%;
  }
  .watch-video .video .vid-icon i {
    font-size: 15px;
  }
  .service-text p {
    font-size: 13px;
  }
}/*# sourceMappingURL=home.css.map */