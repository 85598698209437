.line {
  display: flex;
}

.radio {
  display: flex;
  align-items: center;
  gap: 20px;
}

.proceed {
  display: flex;
  gap: 30px;
}

.third-form {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-top: 30px;
}
.third-form h1 {
  font-size: 23px;
}
.third-form .payment-text {
  background-color: rgba(0, 115, 255, 0.173);
  padding: 25px;
  border-radius: 10px;
  margin-block: 6px;
}
.third-form .mobile {
  border: 1px solid #EDEDED;
  padding: 15px;
  border-radius: 10px;
}

.button-icon {
  cursor: pointer;
}

.how-to-apply {
  margin-top: 70px;
}
.how-to-apply .apply-content {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  gap: 20px;
}
.how-to-apply .apply-content h1 {
  font-size: 40px;
  font-weight: 700;
  line-height: 50px;
  color: #145C74;
}
.how-to-apply .apply-content p {
  font-size: 24px;
  color: #4F4F4F;
  line-height: 28px;
}
.how-to-apply .registration-form {
  display: grid;
  justify-content: center;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 50px;
  margin-top: 60px;
}
.how-to-apply .registration-form .registration-item {
  display: flex;
  flex-direction: column;
  gap: 50px;
  background-color: #FBFBFB;
  border-radius: 40px;
  padding: 40px;
  padding-top: 20px;
  align-items: center;
}
.how-to-apply .registration-form .registration-item h1 {
  font-size: 22px;
  font-weight: 400;
  color: #2D2D2D;
  text-align: center;
}
.how-to-apply .registration-form .registration-item .regis-icon {
  background-color: white;
  width: 200px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}

.payment-icon {
  border: 1.8px solid black;
  width: 24px;
  height: 24px;
  border-radius: 50px;
  align-items: center;
  display: flex;
  justify-content: center;
  cursor: pointer;
  margin-left: auto;
}

.popup-form {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10;
}

.popup-content {
  position: fixed;
  z-index: 100;
  top: 50%;
  margin-top: 50px;
  left: 50%;
  transform: translate(-50%, -50%);
  height: -moz-fit-content;
  height: fit-content;
  width: -moz-fit-content;
  width: fit-content;
  background-color: white;
  padding: 30px;
  border-radius: 24px;
}
.popup-content h3 {
  margin-top: 0;
  font-size: 18px;
  font-weight: 700;
}
.popup-content span {
  color: #145C74;
}
.popup-content .header-popup {
  display: flex;
  flex-direction: column;
  text-align: left;
  gap: 15px;
  padding-bottom: 20px;
  padding-top: 30px;
}
.popup-content .header-popup h2 {
  font-size: 16px;
  font-weight: 700;
}
.popup-content .header-popup h3 {
  font-size: 16px;
  font-weight: 600;
}
.popup-content .header-popup h3 span {
  color: #145C74;
}
.popup-content .second-form {
  margin-top: 60px;
}
.popup-content .second-buttons {
  display: flex;
  gap: 10px;
  margin-top: 60px;
}
.popup-content .application-form {
  display: flex;
  align-items: center;
  position: relative;
}
.popup-content .popup-buttons {
  display: flex;
  gap: 10px;
  justify-content: flex-end;
}
.popup-content form select {
  color: black !important;
}
.popup-content form .form-elements {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}
.popup-content form input[type=text],
.popup-content form input[type=email],
.popup-content form select {
  width: 100%;
  padding: 16px;
  margin: 10px 0;
  border: none;
  border-radius: 8px;
  background-color: #F8F8F8;
  box-sizing: border-box;
  color: black;
  font-size: 16px;
}
.popup-content label {
  color: #858585;
  font-size: 16px;
}
.popup-content .submitted {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 40px;
  gap: 20px;
}
.popup-content .submitted .submitted-icon {
  width: 96.52px;
  height: 96.52px;
  background-color: #145C74;
  border-radius: 50%;
  align-items: center;
  display: flex;
  justify-content: center;
}
.popup-content .submitted .icon-border {
  width: 140px;
  height: 140px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: 8px solid rgba(7, 161, 175, 0.0784313725);
}
.popup-content .submitted h2 {
  font-size: 20px;
  color: #145C74;
}
.popup-content .submitted p {
  color: #4F4F4F;
  font-size: 20px;
}
.popup-content .submitted .submitted-button {
  width: -moz-fit-content;
  width: fit-content;
  height: 46px;
  font-size: 16px;
  font-weight: 400;
  padding-left: 30px;
}
.popup-content button {
  background-color: #145C74;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 8px;
  margin-top: 10px;
  max-width: 163px;
  width: 100%;
  height: 100%;
  max-height: 46px;
  text-align: center;
}
.popup-content button.close {
  background-color: #F0F0F0;
  color: #8D8E90;
}
.popup-content button.close:hover, .popup-content button.close:focus {
  background-color: #145C74;
}
.popup-content button.next:hover, .popup-content button.next:focus {
  background-color: black;
}

@media screen and (max-width: 1300px) {
  .how-to-apply .registration-form .registration-item h1 br {
    display: block;
  }
}
@media screen and (max-width: 1340px) {
  .how-to-apply .registration-form {
    gap: 10px;
  }
}
@media screen and (max-width: 1200px) {
  .how-to-apply .registration-form .registration-item {
    padding: 20px;
  }
}
@media screen and (max-width: 1180px) {
  .how-to-apply .apply-content {
    padding: 10px;
  }
}
@media screen and (max-width: 1080px) {
  .how-to-apply .apply-content {
    padding: 20px;
  }
  .how-to-apply .apply-content p br {
    display: none;
  }
  .how-to-apply .registration-form {
    display: grid;
    grid-template-columns: 40% 40%;
    gap: 50px;
  }
  .how-to-apply .registration-form .registration-item {
    padding: 40px;
  }
}
@media screen and (max-width: 980px) {
  .bottom-footer {
    gap: 30px;
  }
}
@media screen and (max-width: 780px) {
  .how-to-apply .registration-form {
    grid-template-columns: 40% 40%;
  }
}
@media screen and (max-width: 600px) {
  .how-to-apply .registration-form {
    grid-template-columns: 100%;
    padding-inline: 40px;
  }
}
@media screen and (max-width: 765px) {
  .popup-content .header-popup h2 br {
    display: none;
  }
}
@media (max-width: 550px) {
  .how-to-apply .apply-content h1 {
    font-size: 30px;
    line-height: 40px;
  }
}/*# sourceMappingURL=howtoapply.css.map */