@import '../variables/_variables.scss';

.partnership-section{

    .group1-img{
        width: 50%;
    }
    .group2-img{
        width: 50%;
    }
    .part-container{
    .parnership-tex{
        display: flex;
        justify-content: center;
        padding-top: 60px;
        align-items: center;
    .text{
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        align-items: center;
        gap: 20px;

       
        
        
        .text-paragraph{
            color: $primary-color;
            text-align: center;

        }
    
    }

    }




    .training-images{
        margin-top: 50px;
        .training-container{
            padding: 70px;
            display: flex;
            flex-direction: row;
            gap: 40px;
            .text-container{
                margin-top: 50px;
                $secondary-color: #4F4F4F;
                 font-size: 18px;
                 font-weight: 400; 
            }

            .images-container{
                .image1{
                    img{
                        width: 500px;
                    }
                }
            }
        }
    }


    .bluebackground-section{
        background-color: $primary-color;
        margin-top: 80px;
        background-image: url("/public/images/footer/footer_cover.svg");
        height: 100%;
        background-position: cover;
        background-repeat: no-repeat;

        .training-group{
            padding-block: 70px;
            padding-inline: 70px;
            display: flex;
            width: 100%;
           
            flex-direction: row;
            gap: 25px;

            }
            .text-white{
                margin-top: 18px;
                p{
                    color:#FFFFFF ;
                     font-weight: 400;
                     line-height: 22px;
                }
              

            }


    //         .group2-img{
    //             .group-a{
    //                 img{
    //                     width: 500px;
    //                     height: 200px;
    //                 }
    //             }
    //             .group-b{
    //                 margin-top: 18px;
    //                 img{
    //                     width: Hug (579px)px;
    //                     height: Hug (51px)px;
    //                     gap: 16px;
    //                     opacity: 0px;
                        
    //                 }
    
    //           }
    //             .text-white{
    //                 color:#FFFFFF ;
     
    //              }

           
    // }
            }
        }



    .benefits{
        .benefits-container{
            padding: 70px;
            .benefitsofPartnerships{
                    flex-direction: column;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                .benefit-us{
                   color: #145C74;
                }
                .color-blue{
                    color: #145C74;

                }
                h1{
                    font-family: Lexend;
                    font-size: 40px;
                    font-weight: 700;
                    line-height: 50px;
                    letter-spacing: -0.045em;
                    text-align: center;

                }
             
            }

            .benefit1{
                 padding:40px 20px 30px;
                 gap: 40px;
                //  background-color: red;
                 background: #FBFBFB;
                //  display: flex;
                //  flex-direction: column;
                //  width: 588px;
                //  height: 200px;  
                h3{
                    color: #145C74;
                }

                .singler-benefit-a{
                    display: flex;
                    flex-direction: column;
                     gap: 22px;
                 p{
                    font-family: Lexend;
                    font-weight: 400;
                      line-height: 25px;
                 }
                }
            }

            .benefit2{
                padding:40px 20px 30px;
                background: #FBFBFB;
                // display: flex;
                // flex-direction: column;
                // width: 588px;
                // height: 200px; 
                h3{
                    color: #145C74;
                }
                .singler-benefit-b{
                    display: flex;
                    flex-direction: column;
                     gap: 22px;
                 p{
                    font-family: Lexend;
                    font-weight: 400;
                      line-height: 22px;
                 }
                }
            }
            .benefit3{
                margin-top: 32px;
                height: 200px; 
                padding:42px 20px 30px;
                background: #FBFBFB;
                h3{
                    color: #145C74;
                }
                .benefit-5{
                    display: flex;
                    flex-direction: column;
                     gap: 22px;
                 p{
                      font-weight: 400;
                      line-height: 23px;
                 }
                }
                
            }
         
        }
    }
    }

.benefit-rows{
    gap:32px;
    display: grid;
    grid-template-columns: 1fr 1fr;
}



// Media queries for smaller screens
@media  (max-width: 1300px) {
    .group-a{
        img{
            width: 500px;
        }
    }
}

@media (max-width: 1024px) {
    .partnership-section .part-container .bluebackground-section {
      .training-group {
        padding: 35px; 
        flex-direction: row; 
        gap: 20px;
  
        .group-a {
          img {
            width: 100%; 
            height: auto; 
          }
        }
  
        .group-b {
          margin-top: 20px;
          img {
            max-width: 100%;
            height: auto;
            opacity: 1; 
          }
        }
  
        .text-white {
          margin-top: 18px;
          p {
            color: #ffffff;
            font-weight: 400;
            line-height: 22px;
          }
        }
  
        .group2-img {
          .group-a {
            img {
              width: 100%; 
              height: auto; 
            }
          }
  
          .group-b {
            margin-top: 18px;
            img {
              max-width: 100%; 
              height: auto;
              opacity: 1;
            }
          }
  
          .text-white {
            width: 100%; 
          }
        }
      }
    }
  }

@media (max-width: 768px) {
  .benefit-rows {
    grid-template-columns: 1fr;
  }
  .partnership-section {
    .part-container {
      .parnership-tex {
        padding: 70px;
        padding-top: 20px;

        .text {
          gap: 10px;
        }
      }

      .training-images {
        .training-container {
            padding: 30px;
            display: flex;
            flex-direction: column;
            gap: 15px;
          .text-container {
            font-size: 14px;
          }

          .images-container {
            .image1 {
              img {
                width: 100%;
              }
            }
          }
        }
      }

      .bluebackground-section {
        width: 100%;
        .training-group {
          width: 100%;
          // padding: 30px;
          display: flex;
          flex-direction: column;
          gap: 20px;
        .group1-img{
          width: 100%;
        }
        .group2-img{
          width: 100%;
        }
            .group-a {
              img {
                width: 100%; 
                height: auto; 
              }
            }
      
            .group-b {
              margin-top: 20px;
              img {
                width: 100%;
                height: auto;
                opacity: 1; 
              }
            }
      
            .text-white {
              margin-top: 18px;
              p {
                color: #ffffff;
                font-weight: 400;
                line-height: 22px;
              }
            }
      
            .group2-img {
              .group-a {
                img {
                  width: 100%; 
                  height: auto; 
                }
              }
      
              .group-b {
                margin-top: 18px;
                img {
                  width: 100%; 
                  height: auto;
                  opacity: 1;
                }
              }
      
              .text-white {
                width: 100%; 
              }
            }
          }
      }

     

      .benefits {
        .benefits-container {
          padding: 30px;

          .benefitsofPartnerships {
            h1 {
              font-size: 24px;
              line-height: 30px;
            }
          }

          .benefit1,
          .benefit2,
          .benefit3 {
            padding: 20px 10px;
          }
        }
      }
    

     
      

      .benefits {
        .benefits-container {
          padding: 30px;

          .benefitsofPartnerships {
            h1 {
              font-size: 24px;
              line-height: 30px;
            }
          }

          .benefit1,
          .benefit2,
          .benefit3 {
            padding: 20px 10px;
          }
        }
      }
    }
  }
}


  
  
  





  
  
  
  



  


























