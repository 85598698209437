.profile-loading-container {
    max-width: 90vw;
    margin: auto;
}

.section-title {
    padding: 8px 12px;
    border-left: 5px solid #07A1AF;
    color: #07A1AF;

    h1 {
        font-size: 28px;
    }

}

.user-profile-page {
    max-width: 90vw;
    height: unset;
    margin: auto;
    color: #3F3F3F;



    small {
        color: gray;
        font-size: 12px;
    }

    .profile-container {
        border-radius: 12px;

        .profile-info {
            align-items: center;

            .profile-pic {
                img {
                    height: 98px;
                    width: 98px;
                    border-radius: 12px;
                    background-position: top center;
                }
            }

            .names-role {
                gap: 3px;

                .names {
                    h3 {
                        font-size: 18px;
                    }
                }

                .role {
                    h4 {
                        font-size: 14px;
                    }
                }
            }

            .email-phone-number {

                .phone-number,
                .email {
                    background-color: rgb(236, 236, 236);
                    padding: 12px 24px;
                    border-radius: 6px;
                }
            }

        }
    }

    .courses-section {
        border-radius: 12px;

        .courses-container {
            .courses-grid {

                .course {
                    display: flex;
                    flex-direction: column;
                    justify-content: end;
                    padding: 12px;
                    color: white;
                    gap: 3px;
                    border-radius: 12px;

                    h3 {
                        font-size: 16px;
                    }

                    small {
                        color: rgb(237, 237, 237);
                        font-size: 14px;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1080px) {
    .section-title {
        h1 {
            font-size: 18px;
        }
    }

    .user-profile-page,
    .profile-loading-container {
        max-width: 100vw;
        padding: 20px;
    }

    .user-profile-page {
        .profile-container {
            .profile-info {
                .profile-pic {
                    img {
                        height: 72px;
                        width: 72px;
                    }
                }

                gap: 12px;

                .names-role {
                    gap: 0;
                }

                .email-phone-number {

                    .phone-number,
                    .email {
                        width: 100%;
                    }
                }
            }
        }
    }
}