.applications-page .container {
  margin: auto;
  padding: 50px 0;
}
.applications-page .container .applications-placeholder {
  display: grid;
  gap: 24px;
}
.applications-page .container .title {
  display: flex;
  align-items: center;
  gap: 12px;
}
.applications-page .container .applications-count {
  height: 24px;
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  color: #145C74;
}
.applications-page .container .applications {
  display: grid;
  gap: 24px;
}
.applications-page .container .applications .actions-filters {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.applications-page .container .applications .actions-filters .search-input {
  display: flex;
  align-items: center;
  gap: 12px;
  border-radius: 12px;
  border: 1px solid whitesmoke;
  padding-right: 4px;
}
.applications-page .container .applications .actions-filters .search-input input {
  border: 0;
  width: 400px;
}
.applications-page .container .applications .actions-filters .search-input button {
  padding: 8px 12px;
}
.applications-page .container .applications .actions-filters .filters {
  display: flex;
  align-items: center;
  gap: 12px;
}

table {
  width: 100%;
  border-collapse: collapse;
  border-radius: 12px;
}
table th,
table td {
  border-bottom: 1px solid #ccc;
  padding: 10px;
  text-align: start;
}
table thead tr {
  background-color: #145C74;
  color: white;
  border-radius: 12px;
}
table tbody {
  font-weight: 300;
}
table tbody tr:nth-child(even) {
  background-color: #f2f2f2;
}/*# sourceMappingURL=cohordApplications.css.map */