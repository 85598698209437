.partnership-section .group1-img {
  width: 50%;
}
.partnership-section .group2-img {
  width: 50%;
}
.partnership-section .part-container .parnership-tex {
  display: flex;
  justify-content: center;
  padding-top: 60px;
  align-items: center;
}
.partnership-section .part-container .parnership-tex .text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;
  gap: 20px;
}
.partnership-section .part-container .parnership-tex .text .text-paragraph {
  color: #145C74;
  text-align: center;
}
.partnership-section .part-container .training-images {
  margin-top: 50px;
}
.partnership-section .part-container .training-images .training-container {
  padding: 70px;
  display: flex;
  flex-direction: row;
  gap: 40px;
}
.partnership-section .part-container .training-images .training-container .text-container {
  margin-top: 50px;
  font-size: 18px;
  font-weight: 400;
}
.partnership-section .part-container .training-images .training-container .images-container .image1 img {
  width: 500px;
}
.partnership-section .part-container .bluebackground-section {
  background-color: #145C74;
  margin-top: 80px;
  background-image: url("/public/images/footer/footer_cover.svg");
  height: 100%;
  background-position: cover;
  background-repeat: no-repeat;
}
.partnership-section .part-container .bluebackground-section .training-group {
  padding-block: 70px;
  padding-inline: 70px;
  display: flex;
  width: 100%;
  flex-direction: row;
  gap: 25px;
}
.partnership-section .part-container .bluebackground-section .text-white {
  margin-top: 18px;
}
.partnership-section .part-container .bluebackground-section .text-white p {
  color: #FFFFFF;
  font-weight: 400;
  line-height: 22px;
}
.partnership-section .benefits .benefits-container {
  padding: 70px;
}
.partnership-section .benefits .benefits-container .benefitsofPartnerships {
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
}
.partnership-section .benefits .benefits-container .benefitsofPartnerships .benefit-us {
  color: #145C74;
}
.partnership-section .benefits .benefits-container .benefitsofPartnerships .color-blue {
  color: #145C74;
}
.partnership-section .benefits .benefits-container .benefitsofPartnerships h1 {
  font-family: Lexend;
  font-size: 40px;
  font-weight: 700;
  line-height: 50px;
  letter-spacing: -0.045em;
  text-align: center;
}
.partnership-section .benefits .benefits-container .benefit1 {
  padding: 40px 20px 30px;
  gap: 40px;
  background: #FBFBFB;
}
.partnership-section .benefits .benefits-container .benefit1 h3 {
  color: #145C74;
}
.partnership-section .benefits .benefits-container .benefit1 .singler-benefit-a {
  display: flex;
  flex-direction: column;
  gap: 22px;
}
.partnership-section .benefits .benefits-container .benefit1 .singler-benefit-a p {
  font-family: Lexend;
  font-weight: 400;
  line-height: 25px;
}
.partnership-section .benefits .benefits-container .benefit2 {
  padding: 40px 20px 30px;
  background: #FBFBFB;
}
.partnership-section .benefits .benefits-container .benefit2 h3 {
  color: #145C74;
}
.partnership-section .benefits .benefits-container .benefit2 .singler-benefit-b {
  display: flex;
  flex-direction: column;
  gap: 22px;
}
.partnership-section .benefits .benefits-container .benefit2 .singler-benefit-b p {
  font-family: Lexend;
  font-weight: 400;
  line-height: 22px;
}
.partnership-section .benefits .benefits-container .benefit3 {
  margin-top: 32px;
  height: 200px;
  padding: 42px 20px 30px;
  background: #FBFBFB;
}
.partnership-section .benefits .benefits-container .benefit3 h3 {
  color: #145C74;
}
.partnership-section .benefits .benefits-container .benefit3 .benefit-5 {
  display: flex;
  flex-direction: column;
  gap: 22px;
}
.partnership-section .benefits .benefits-container .benefit3 .benefit-5 p {
  font-weight: 400;
  line-height: 23px;
}

.benefit-rows {
  gap: 32px;
  display: grid;
  grid-template-columns: 1fr 1fr;
}

@media (max-width: 1300px) {
  .group-a img {
    width: 500px;
  }
}
@media (max-width: 1024px) {
  .partnership-section .part-container .bluebackground-section .training-group {
    padding: 35px;
    flex-direction: row;
    gap: 20px;
  }
  .partnership-section .part-container .bluebackground-section .training-group .group-a img {
    width: 100%;
    height: auto;
  }
  .partnership-section .part-container .bluebackground-section .training-group .group-b {
    margin-top: 20px;
  }
  .partnership-section .part-container .bluebackground-section .training-group .group-b img {
    max-width: 100%;
    height: auto;
    opacity: 1;
  }
  .partnership-section .part-container .bluebackground-section .training-group .text-white {
    margin-top: 18px;
  }
  .partnership-section .part-container .bluebackground-section .training-group .text-white p {
    color: #ffffff;
    font-weight: 400;
    line-height: 22px;
  }
  .partnership-section .part-container .bluebackground-section .training-group .group2-img .group-a img {
    width: 100%;
    height: auto;
  }
  .partnership-section .part-container .bluebackground-section .training-group .group2-img .group-b {
    margin-top: 18px;
  }
  .partnership-section .part-container .bluebackground-section .training-group .group2-img .group-b img {
    max-width: 100%;
    height: auto;
    opacity: 1;
  }
  .partnership-section .part-container .bluebackground-section .training-group .group2-img .text-white {
    width: 100%;
  }
}
@media (max-width: 768px) {
  .benefit-rows {
    grid-template-columns: 1fr;
  }
  .partnership-section .part-container .parnership-tex {
    padding: 70px;
    padding-top: 20px;
  }
  .partnership-section .part-container .parnership-tex .text {
    gap: 10px;
  }
  .partnership-section .part-container .training-images .training-container {
    padding: 30px;
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  .partnership-section .part-container .training-images .training-container .text-container {
    font-size: 14px;
  }
  .partnership-section .part-container .training-images .training-container .images-container .image1 img {
    width: 100%;
  }
  .partnership-section .part-container .bluebackground-section {
    width: 100%;
  }
  .partnership-section .part-container .bluebackground-section .training-group {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  .partnership-section .part-container .bluebackground-section .training-group .group1-img {
    width: 100%;
  }
  .partnership-section .part-container .bluebackground-section .training-group .group2-img {
    width: 100%;
  }
  .partnership-section .part-container .bluebackground-section .training-group .group-a img {
    width: 100%;
    height: auto;
  }
  .partnership-section .part-container .bluebackground-section .training-group .group-b {
    margin-top: 20px;
  }
  .partnership-section .part-container .bluebackground-section .training-group .group-b img {
    width: 100%;
    height: auto;
    opacity: 1;
  }
  .partnership-section .part-container .bluebackground-section .training-group .text-white {
    margin-top: 18px;
  }
  .partnership-section .part-container .bluebackground-section .training-group .text-white p {
    color: #ffffff;
    font-weight: 400;
    line-height: 22px;
  }
  .partnership-section .part-container .bluebackground-section .training-group .group2-img .group-a img {
    width: 100%;
    height: auto;
  }
  .partnership-section .part-container .bluebackground-section .training-group .group2-img .group-b {
    margin-top: 18px;
  }
  .partnership-section .part-container .bluebackground-section .training-group .group2-img .group-b img {
    width: 100%;
    height: auto;
    opacity: 1;
  }
  .partnership-section .part-container .bluebackground-section .training-group .group2-img .text-white {
    width: 100%;
  }
  .partnership-section .part-container .benefits .benefits-container {
    padding: 30px;
  }
  .partnership-section .part-container .benefits .benefits-container .benefitsofPartnerships h1 {
    font-size: 24px;
    line-height: 30px;
  }
  .partnership-section .part-container .benefits .benefits-container .benefit1,
  .partnership-section .part-container .benefits .benefits-container .benefit2,
  .partnership-section .part-container .benefits .benefits-container .benefit3 {
    padding: 20px 10px;
  }
  .partnership-section .part-container .benefits .benefits-container {
    padding: 30px;
  }
  .partnership-section .part-container .benefits .benefits-container .benefitsofPartnerships h1 {
    font-size: 24px;
    line-height: 30px;
  }
  .partnership-section .part-container .benefits .benefits-container .benefit1,
  .partnership-section .part-container .benefits .benefits-container .benefit2,
  .partnership-section .part-container .benefits .benefits-container .benefit3 {
    padding: 20px 10px;
  }
}/*# sourceMappingURL=partnership.css.map */