@import '../variables/variables.scss';

.header {
  top: 0;
  position: sticky;
  background-color: white;
  z-index: 1000;

  .link {
    color: black;
  }

  .nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 90vw;
    margin: auto;
    padding-block: 15px;
    height: $nav-height;

    .branding {
      .logo {
        max-width: 138px;
      }
    }

    ul {
      list-style: none;
      display: flex;
      align-items: center;
      gap: 30px;
      color: $primary-color;

      li {
        cursor: pointer;
      }
    }

    .text-button {
      display: flex;
      gap: 60px;
      align-items: center;

    }
  }

  .menu-icon {
    display: none;
  }
}

@media screen and (max-width: 1080px) {
  .header {
    .nav {
      .branding {
        .logo {
          max-width: 92px;
        }
      }

      ul {
        display: none;
      }

      .menu-icon {
        display: block;

        .close-menu {
          display: none;
        }
      }
    }

    .open {
      ul {
        display: grid;
        justify-items: center;
        align-items: start;
        align-content: start;
        gap: 24px;
        position: absolute;
        left: 0;
        right: 0;
        top: $nav-height;
        background-color: white;
        animation: openMenuAnimation linear 0.2s forwards;

      }

      @keyframes openMenuAnimation {
        0% {
          height: 0;
        }

        100% {
          height: 100vh;
        }
      }

      .menu-icon {
        .close-menu {
          display: flex;
        }

        .open-menu {
          display: none;
        }
      }
    }
  }
}

a {
  text-decoration: none;
  color: inherit;
}

.authenticated-header {
  background-color: #145C74;
  color: white;

  .navigation {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 90vw;
    margin-inline: auto;
    height: 82px;

    .links {
      display: flex;
      gap: 24px;
      align-items: center;
    }

    .link {
      display: flex;
      align-items: center;
      gap: 12px;
      padding: 16px 24px;
      border-radius: 8px;
    }

    .active {
      background-color: #216D87;
    }

    .profile-links {
      display: flex;
      gap: 12px;
      align-items: center;

      .profile {
        display: flex;
        align-items: center;
        gap: 6px;
      }
    }
  }
}