.header {
  top: 0;
  position: sticky;
  background-color: white;
  z-index: 1000;
}
.header .link {
  color: black;
}
.header .nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 90vw;
  margin: auto;
  padding-block: 15px;
  height: 100px;
}
.header .nav .branding .logo {
  max-width: 138px;
}
.header .nav ul {
  list-style: none;
  display: flex;
  align-items: center;
  gap: 30px;
  color: #145C74;
}
.header .nav ul li {
  cursor: pointer;
}
.header .nav .text-button {
  display: flex;
  gap: 60px;
  align-items: center;
}
.header .menu-icon {
  display: none;
}

@media screen and (max-width: 1080px) {
  .header .nav .branding .logo {
    max-width: 92px;
  }
  .header .nav ul {
    display: none;
  }
  .header .nav .menu-icon {
    display: block;
  }
  .header .nav .menu-icon .close-menu {
    display: none;
  }
  .header .open ul {
    display: grid;
    justify-items: center;
    align-items: start;
    align-content: start;
    gap: 24px;
    position: absolute;
    left: 0;
    right: 0;
    top: 100px;
    background-color: white;
    animation: openMenuAnimation linear 0.2s forwards;
  }
  @keyframes openMenuAnimation {
    0% {
      height: 0;
    }
    100% {
      height: 100vh;
    }
  }
  .header .open .menu-icon .close-menu {
    display: flex;
  }
  .header .open .menu-icon .open-menu {
    display: none;
  }
}
a {
  text-decoration: none;
  color: inherit;
}

.authenticated-header {
  background-color: #145C74;
  color: white;
}
.authenticated-header .navigation {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 90vw;
  margin-inline: auto;
  height: 82px;
}
.authenticated-header .navigation .links {
  display: flex;
  gap: 24px;
  align-items: center;
}
.authenticated-header .navigation .link {
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 16px 24px;
  border-radius: 8px;
}
.authenticated-header .navigation .active {
  background-color: #216D87;
}
.authenticated-header .navigation .profile-links {
  display: flex;
  gap: 12px;
  align-items: center;
}
.authenticated-header .navigation .profile-links .profile {
  display: flex;
  align-items: center;
  gap: 6px;
}/*# sourceMappingURL=header.css.map */