@import url("https://fonts.googleapis.com/css2?family=Lexend:wght@100..900&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-size: 1.2vw;
  font-family: "Lexend", sans-serif;
}

.container {
  width: 90vw;
}

.hero-link {
  color: white;
}
.hero-link:hover {
  color: #145C74;
  background-color: transparent;
}

.buttons {
  display: flex;
  align-content: center;
  gap: 24px;
}

button,
.button {
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 12px 62px 12px 48px;
  position: relative;
  outline: none;
  border-radius: 6px;
  border: 2px solid transparent;
  font-family: inherit;
  font-size: inherit;
  transition: linear 0.3s;
  text-wrap: nowrap;
  cursor: pointer;
}
button .icon,
.button .icon {
  height: 32px;
  width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  position: absolute;
  right: 8px;
}

.primary-button {
  background-color: #145C74;
  color: #EFFEFF;
  border-color: #145C74;
}
.primary-button .icon {
  background-color: #EFFEFF;
  color: #145C74;
}
.primary-button:hover {
  background-color: #EFFEFF;
  color: #145C74;
}
.primary-button:hover .icon {
  background-color: #145C74;
  color: #EFFEFF;
}

.secondary-button {
  background-color: #EFFEFF;
  border-color: #145C74;
}
.secondary-button .icon {
  background-color: #145C74;
  color: #EFFEFF;
}
.secondary-button:hover {
  background-color: #145C74;
  color: #EFFEFF;
}
.secondary-button:hover .icon {
  background-color: #EFFEFF;
  color: #145C74;
}

.hero-section {
  height: calc(100vh - 100px);
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.hero-section .move-down-icon {
  position: absolute;
  margin: auto;
  bottom: 24px;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.hero-section .move-down-icon .circle {
  height: 85px;
  width: 85px;
  border-radius: 85px;
  animation: rotateCircle linear 10s infinite;
}
.hero-section .move-down-icon .icon {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  height: 52px;
  width: 52px;
  border-radius: 52px;
  background-color: #145C74;
}
@keyframes rotateCircle {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.hero-section .pattern-left {
  position: absolute;
  left: 0;
  height: 100vh;
}
.hero-section .pattern-right {
  position: absolute;
  right: 0;
  height: 100vh;
}
.hero-section .container {
  display: flex;
  align-items: center;
  justify-content: center;
}
.hero-section .hero-content {
  margin-top: -48px;
  display: grid;
  height: -moz-fit-content;
  height: fit-content;
  justify-items: center;
  text-align: center;
  width: 70vw;
  gap: 24px;
  padding: 1vw;
  position: relative;
  z-index: 1;
}
.hero-section .hero-content .hero-title {
  text-transform: capitalize;
  font-size: 3vw;
}
.hero-section .hero-content .hero-title span {
  color: #145C74;
}
.hero-section .hero-images {
  position: relative;
}
.hero-section .hero-images img {
  border-radius: 24px;
  width: 20vw;
  -o-object-fit: cover;
     object-fit: cover;
}
.hero-section .hero-images .small {
  position: absolute;
  right: -64px;
  bottom: -64px;
  border: 12px solid white;
  width: 12vw;
}
.hero-section .hero-images .right {
  right: unset;
  left: -64px;
}

@media screen and (max-width: 1080px) {
  body {
    font-size: 16px;
  }
  .hero-section {
    height: 680px;
    padding-top: 120px;
  }
  .hero-section .move-down-icon {
    bottom: -80px;
  }
  .hero-section .pattern-right,
  .hero-section .pattern-left {
    height: auto;
    width: 50%;
  }
  .hero-section .container {
    justify-content: space-between;
    gap: 48px;
    justify-content: space-between;
  }
  .hero-section .container .hero-content {
    width: 50%;
    text-align: start;
    justify-items: start;
  }
  .hero-section .container .hero-content .hero-title {
    font-size: 32px;
  }
  .hero-section .container .hero-images {
    width: 50%;
    display: flex;
    justify-content: end;
  }
  .hero-section .container .hero-images .small {
    width: 40%;
  }
  .hero-section .container .hero-images img {
    width: 70%;
  }
  .hero-section .container .hero-images .right {
    left: 20px;
  }
  .hero-section .container .left-images {
    flex: 1 1 300px;
    display: none;
  }
}
@media screen and (max-width: 780px) {
  .buttons {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
  .hero-section {
    height: auto;
  }
  .hero-section .pattern-right {
    display: none;
  }
  .hero-section .move-down-icon {
    display: none;
  }
  .hero-section .pattern-left {
    width: 100%;
  }
  .hero-section .container {
    flex-direction: column;
    gap: 62px;
    text-align: center;
  }
  .hero-section .container .hero-content {
    width: 100%;
    text-align: center;
    justify-items: center;
  }
  .hero-section .container .hero-content .hero-title {
    font-size: 22px;
  }
  .hero-section .container .hero-images {
    width: 100%;
    margin-top: 50px;
  }
  .hero-section .container .hero-images img {
    width: 100%;
    height: 30vh;
  }
  .hero-section .container .hero-images .small {
    top: -80px;
    left: 0;
    right: 0;
    margin-inline: auto;
    height: auto;
  }
}/*# sourceMappingURL=hero_section.css.map */