.application-page {

    .container {
        margin: auto;
        display: flex;
        align-items: baseline;
        flex-wrap: wrap;
        gap: 48px;
        padding: 50px 0;

        .content {
            flex: 1 1 400px;
            display: grid;
            height: fit-content;
            gap: 24px;

            .title {
                display: flex;
                align-items: center;
                gap: 12px;
                color: #145C74;

                .icon {
                    height: 46px;
                    width: 46px;
                    background-color: #EAF9FF;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 12px;

                }
            }

            .trainings {
                display: flex;
                flex-wrap: wrap;
                gap: 12px;

                .training {
                    border-radius: 12px;
                    max-width: 298px;
                    padding: 12px;
                    background-color: whitesmoke;
                    display: flex;
                    align-items: start;
                    gap: 12px;

                    .icon {
                        background-color: white;
                        height: 46px;
                        width: 46px;
                        border-radius: 8px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }

                    .training-content {
                        flex: 1;

                        h4 {
                            font-size: 16px
                        }

                        p {
                            font-size: 14px;
                        }
                    }
                }
            }
        }

        .form {
            flex: 1 1 400px;

            button {
                width: fit-content;
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .application-page {
        .container {
            .content {
                .trainings {
                    .training {
                        max-width: 100%;
                        flex: 1 1 100px;
                        flex-direction: column;
                        align-items: center;
                        text-align: center;

                        .training-content {
                            h4 {
                                font-size: 12px;
                            }

                            p {
                                display: none;
                            }
                        }
                    }
                }
            }
        }
    }
}

.success-message {
    background-color: #145C74;
    padding: 12px;
    color: white;
    border-radius: 12px;
}