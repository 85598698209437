.services-container {
  width: 90vw;
  margin: auto;
  display: flex;
  padding-top: 67px;
  font-size: 18px;
  justify-content: space-between;
  justify-content: center;
  flex-wrap: wrap;
  gap: 24px;
}
.services-container .services-first-row {
  flex: 1 1 300px;
  display: flex;
  flex-direction: column;
  gap: 48px;
}
.services-container .services-first-row .service-image {
  max-width: 300px;
  max-height: 585px;
}
.services-container .services-first-row .service-image img {
  max-width: 100%;
  height: auto;
}
.services-container .services-first-row h3 {
  font-weight: 500;
  font-size: 32px;
  color: #145C74;
  max-width: 390px;
}
.services-container .services-second-row {
  flex: 1 1 500px;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
}
.services-container .services-second-row .services-details {
  display: flex;
  flex-direction: column;
  gap: 40px;
}
.services-container .services-second-row .services-details .name-price {
  display: grid;
  gap: 12px;
}
.services-container .services-second-row .services-details .name-price .price {
  color: #145C74;
  background-color: rgba(20, 92, 116, 0.1764705882);
  padding: 6px 24px;
  border-radius: 8px;
  width: -moz-fit-content;
  width: fit-content;
}
.services-container .services-second-row .services-details .name-price .name {
  display: flex;
  align-items: center;
  gap: 12px;
}
.services-container .services-second-row .services-details .details-text {
  font-weight: 400;
  max-width: 600px;
  display: grid;
  height: -moz-fit-content;
  height: fit-content;
  gap: 24px;
  line-height: 28px;
}
.services-container .services-second-row .services-details .details-text .service-title {
  display: flex;
  align-items: center;
  gap: 12px;
  color: #145C74;
  line-height: 42px;
}
.services-container .services-second-row .services-details .cards {
  display: flex;
  max-width: 100%;
  flex-wrap: wrap;
  gap: 20px;
}
.services-container .services-second-row .services-details .cards .card-container {
  flex: 1 1 250px;
  background-color: #FAFAFA;
  border-radius: 12px;
  padding: 12px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.services-container .services-second-row .services-details .cards .card-container .card-title {
  display: flex;
  align-items: center;
  gap: 12px;
}
.services-container .services-second-row .services-details .cards .card-container .card-title .icon {
  background-color: white;
  max-width: 60px;
  border-radius: 8px;
}
.services-container .services-second-row .services-details .cards .card-container .card-title img {
  max-width: 60px;
}
.services-container .services-second-row .services-details .cards .card-container .card-title .title {
  font-size: 16px;
  color: #145C74;
}
.services-container .services-second-row .services-details .cards .card-container .details {
  color: #686868;
  font-size: 14px;
  line-height: 20px;
}
.services-container .services-second-row .services-details button {
  width: -moz-fit-content;
  width: fit-content;
}

.services-page-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.services-page-container .second-section {
  max-width: 1159px;
  margin-block: 84px;
  display: flex;
  flex-direction: column;
  gap: 27px;
  padding-inline: 80px;
  text-align: center;
  align-items: center;
  justify-content: center;
}
.services-page-container .second-section .title {
  color: #145C74;
  font-weight: 700;
  font-size: 24px;
}
.services-page-container .second-section .details {
  color: #646363;
}
.services-page-container .second-section .detail-title {
  color: #145C74;
  font-weight: 700;
}
.services-page-container .second-section .detail-card {
  display: flex;
  gap: 10px;
  font-size: 16px;
  flex-wrap: wrap;
}
.services-page-container .second-section .detail-card .details-div {
  flex: 1 1 295px;
  border-radius: 8px;
  padding: 12px 30px;
  background-color: whitesmoke;
}
.services-page-container .second-section .detail-card .details-div:nth-child(odd) {
  background-color: #e4e4e4;
}
.services-page-container .second-section .price-and-duration {
  background-color: #F9F9F9;
  display: flex;
  gap: 52px;
  border-radius: 12px;
  padding: 24px;
  justify-content: center;
}
.services-page-container .second-section .price-and-duration .price-div,
.services-page-container .second-section .price-and-duration .duration-div {
  display: flex;
  gap: 22px;
}
.services-page-container .second-section .price-and-duration .price-div .icon,
.services-page-container .second-section .price-and-duration .duration-div .icon {
  background-color: #145C74;
  width: 52px;
  height: 52px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
}
.services-page-container .second-section .price-and-duration .price-div .icon img,
.services-page-container .second-section .price-and-duration .duration-div .icon img {
  max-width: 100%;
  height: auto;
}
.services-page-container .second-section .price-and-duration .price-div .cost,
.services-page-container .second-section .price-and-duration .duration-div .cost {
  display: flex;
  flex-direction: column;
  gap: 8px;
  color: #585858;
  font-size: 16px;
  text-align: left;
}
.services-page-container .second-section .price-and-duration .price-div .cost p:nth-child(2),
.services-page-container .second-section .price-and-duration .duration-div .cost p:nth-child(2) {
  color: #145C74;
}

.bernice-form {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.539);
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
}
.bernice-form .payment-content {
  background-color: white;
  max-width: 600px;
  padding: 24px;
  border-radius: 8px;
  display: grid;
  height: -moz-fit-content;
  height: fit-content;
  gap: 9px;
}
.bernice-form .payment-content .service-info {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.bernice-form .payment-content .service-info .service-name {
  color: #145C74;
}
.bernice-form form {
  display: grid;
  gap: 13px;
  color: rgb(90, 90, 90);
  font-size: 16px;
}
.bernice-form form button {
  text-align: center;
  justify-content: center;
  width: 100%;
}
.bernice-form form .form-link {
  color: #145C74;
  cursor: pointer;
}
.bernice-form form input {
  padding: 12px 16px;
  color: inherit;
  font-size: 16px;
  border-radius: 8px;
  outline: none;
  border: none;
  background-color: #F7F7F7;
}
.bernice-form form input::-moz-placeholder {
  font-size: 16px;
  color: #A9A9A9;
}
.bernice-form form input::placeholder {
  font-size: 16px;
  color: #A9A9A9;
}
.bernice-form .payment-note {
  background-color: #ebebeb;
  padding: 12px;
  border-radius: 8px;
}
.bernice-form .payment-button {
  opacity: 0.5;
}

.loading-content-service {
  height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 24px;
}

@media screen and (max-width: 1200px) {
  .services-container .services-second-row .services-details .cards .card-container .details {
    display: none;
  }
}
@media screen and (max-width: 780px) {
  .services-container {
    gap: 48px;
    padding-top: 0;
  }
  .services-container .services-first-row .service-image {
    max-height: 250px;
    max-width: 100%;
  }
  .services-container .services-first-row .service-image img {
    width: 100%;
    -o-object-fit: cover;
       object-fit: cover;
    height: 250px;
  }
  .services-container .services-second-row .services-details .cards .card-container .details {
    display: none;
  }
  .services-container .services-second-row .services-details button {
    width: 100%;
  }
  .services-page-container .second-section {
    padding-inline: 20px;
  }
  .services-page-container .second-section .price-and-duration {
    max-width: 100%;
    width: 100%;
  }
  .bernice-form {
    padding: 20px;
  }
  .price-and-duration {
    flex-direction: column;
  }
}/*# sourceMappingURL=services.css.map */