@import '../variables/variables.scss';

.services-container {
    width: 90vw;
    margin: auto;
    display: flex;
    padding-top: 67px;
    font-size: $paragraph-font-size;
    justify-content: space-between;
    justify-content: center;
    flex-wrap: wrap;
    gap: 24px;

    .services-first-row {
        flex: 1 1 300px;
        display: flex;
        flex-direction: column;
        gap: 48px;

        .service-image {
            max-width: 300px;
            max-height: 585px;

            img {
                max-width: 100%;
                height: auto;
            }
        }

        h3 {
            font-weight: 500;
            font-size: 32px;
            color: #145C74;
            max-width: 390px;
        }

    }

    .services-second-row {
        flex: 1 1 500px;
        display: flex;
        flex-direction: column;
        border-radius: 8px;


        .services-details {
            display: flex;
            flex-direction: column;
            gap: 40px;

            .name-price {
                display: grid;
                gap: 12px;

                .price {
                    color: $primary-color;
                    background-color: #145c742d;
                    padding: 6px 24px;
                    border-radius: 8px;
                    width: fit-content;
                }

                .name {
                    display: flex;
                    align-items: center;
                    gap: 12px;
                }
            }

            .details-text {
                font-weight: 400;
                max-width: 600px;
                display: grid;
                height: fit-content;
                gap: 24px;
                line-height: 28px;

                .service-title {
                    display: flex;
                    align-items: center;
                    gap: 12px;
                    color: $primary-color;
                    line-height: 42px;
                }
            }

            .cards {
                display: flex;
                max-width: 100%;
                flex-wrap: wrap;
                gap: 20px;

                .card-container {
                    flex: 1 1 250px;
                    background-color: #FAFAFA;
                    border-radius: 12px;
                    padding: 12px;
                    display: flex;
                    flex-direction: column;
                    gap: 8px;

                    .card-title {
                        display: flex;
                        align-items: center;
                        gap: 12px;

                        .icon {
                            background-color: white;
                            max-width: 60px;
                            border-radius: 8px;
                        }

                        img {
                            max-width: 60px;

                        }

                        .title {
                            font-size: 16px;
                            color: $primary-color;
                        }
                    }

                    .details {
                        color: #686868;
                        font-size: 14px;
                        line-height: 20px;
                    }


                }

            }

            button {
                width: fit-content;
            }
        }
    }

}

.services-page-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;



    .second-section {
        max-width: 1159px;
        margin-block: 84px;
        display: flex;
        flex-direction: column;
        gap: 27px;
        padding-inline: 80px;
        text-align: center;
        align-items: center;
        justify-content: center;


        .title {
            color: #145C74;
            font-weight: 700;
            font-size: 24px;
        }

        .details {
            color: #646363;

        }

        .detail-title {
            color: #145C74;
            font-weight: 700;
        }

        .detail-card {
            display: flex;
            gap: 10px;
            font-size: 16px;
            flex-wrap: wrap;

            .details-div {
                flex: 1 1 295px;
                border-radius: 8px;
                padding: 12px 30px;
                background-color: whitesmoke;

            }

            .details-div:nth-child(odd) {
                background-color: #e4e4e4;
            }

        }

        .price-and-duration {
            background-color: #F9F9F9;
            display: flex;
            gap: 52px;
            border-radius: 12px;
            padding: 24px;

            justify-content: center;

            .price-div,
            .duration-div {
                display: flex;
                gap: 22px;

                .icon {
                    background-color: #145C74;
                    width: 52px;
                    height: 52px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 8px;

                    img {
                        max-width: 100%;
                        height: auto;
                    }
                }

                .cost {
                    display: flex;
                    flex-direction: column;
                    gap: 8px;

                    p:nth-child(2) {
                        color: $primary-color;

                    }

                    color: #585858;
                    font-size: 16px;
                    text-align: left;
                }
            }
        }
    }


}

.bernice-form {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    background: rgba(0, 0, 0, 0.539);
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;

    .payment-content {
        background-color: white;
        max-width: 600px;
        padding: 24px;
        border-radius: 8px;
        display: grid;
        height: fit-content;
        gap: 9px;

        .service-info {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .service-name {
                color: $primary-color;
            }

        }
    }

    form {
        display: grid;
        gap: 13px;
        color: rgb(90, 90, 90);
        font-size: 16px;

        button {
            text-align: center;
            justify-content: center;
            width: 100%;
        }

        .form-link {
            color: $primary-color;
            cursor: pointer;
        }

        input {
            padding: 12px 16px;
            color: inherit;
            font-size: 16px;
            border-radius: 8px;
            outline: none;
            border: none;
            background-color: #F7F7F7;

            &::placeholder {
                font-size: 16px;
                color: #A9A9A9;
            }
        }

    }

    .payment-note {
        background-color: #ebebeb;
        padding: 12px;
        border-radius: 8px;
    }

    .payment-button {
        opacity: 0.5;
    }
}

.loading-content-service {
    height: 50vh;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 24px;
}

@media screen and (max-width: 1200px) {
    .services-container {
        .services-second-row {
            .services-details {
                .cards {
                    .card-container {
                        .details {
                            display: none;
                        }

                    }
                }
            }
        }
    }
}

@media screen and (max-width: 780px) {
    .services-container {
        gap: 48px;
        padding-top: 0;

        .services-first-row {

            .service-image {
                max-height: 250px;
                max-width: 100%;

                img {
                    width: 100%;
                    object-fit: cover;
                    height: 250px;
                }
            }
        }

        .services-second-row {
            .services-details {
                .cards {
                    .card-container {
                        .details {
                            display: none;
                        }
                    }
                }

                button {
                    width: 100%;
                }
            }
        }
    }

    .services-page-container {
        .second-section {
            padding-inline: 20px;

            .price-and-duration {
                max-width: 100%;
                width: 100%;
            }
        }
    }

    .bernice-form {
        padding: 20px;
    }

    .price-and-duration {
        flex-direction: column;
    }

}