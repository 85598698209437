@import '../variables/variables.scss';
@import url('https://fonts.googleapis.com/css2?family=Kanit:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Outfit:wght@100..900&display=swap');

.programs-content{
  display: flex;
  gap: 100px;
  padding-inline: 80px;
  padding-top: 60px;
  padding-bottom: 20px;
 

 

  .left-content{
    width: 50%;
    p{
      font-size: 18px;
      line-height: 28px;
      color: $secondary-color;
    }
    .programs-header{
      display: flex;
      flex-direction: column;
      gap: 20px;


      h1{
        color: $primary-color;
        font-weight: 500;
        font-size: 3vw;
      }

     
    }
  }

  .right-content{
    width: 50%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 50px;
  }

  .need-space{
    margin-top: 40px;
  }

  .programs-img{
    padding-block: 30px;
  }

  .skills-text{
    display: flex;
    gap: 10px;

    
    p{
      font-size: 18px;
      color: #505050;
    }
  }

  .skills-content{
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .skills-header{
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding-bottom: 20px;

    .skills-icon{
      background-color: #F3FEFF;
      border-radius: 12px;
      width: 64px;
      height: 64px;
     padding: 10px;
    }

    h3{
      color: $primary-color;
      font-size: 24px;
      font-weight: 700;
      font-family: "Outfit", sans-serif;
    }

  }
}

@media screen and (max-width:1200px){
 .programs-content{
  padding-inline: 50px;
 
 
 }
}

@media screen and (max-width:1080px){
  .programs-content{
   flex-direction: column;
   padding-inline: 80px;

   .left-content{
    width: 100%;
   }
   .right-content{
    width: 100%;

   }
   .programs-img{
    img{
      width: 100%;
    }
  }

  }
 }

 @media screen and (max-width:950px){
  .programs-content{
   padding-inline: 50px;
  
  
  }
 }

 @media screen and (max-width:700px){
  .programs-content{
   padding-inline: 20px;
  
  
  }
 }

 @media screen and (max-width:450px){
  .programs-content{
  .right-content{
    grid-template-columns: 1fr;
  
  }
  
  
  }
 }



