@import '../variables/variables';
@import url('https://fonts.googleapis.com/css2?family=Lexend:wght@100..900&display=swap');


* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    font-size: 1.2vw;
    font-family: "Lexend", sans-serif;
}

.container {
    width: 90vw;
}

.hero-link{
    color: white;

    &:hover{
        color: $primary-color;
        background-color: transparent;
    }
}

.buttons {
    display: flex;
    align-content: center;
    gap: 24px;
    
}

button,
.button {
    display: flex;
    align-items: center;
    gap: 12px;
    padding: 12px 62px 12px 48px;
    position: relative;
    outline: none;
    border-radius: 6px;
    border: 2px solid transparent;
    font-family: inherit;
    font-size: inherit;
    transition: linear 0.3s;
    text-wrap: nowrap;
    cursor: pointer;

    .icon {
        height: 32px;
        width: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 6px;
        position: absolute;
        right: 8px;

    }
}

.primary-button {
    background-color: $primary-color;
    color: #EFFEFF;
    border-color: $primary-color;

    .icon {
        background-color: #EFFEFF;
        color: $primary-color;
    }

    &:hover {
        background-color: #EFFEFF;
        color: $primary-color;

        .icon {
            background-color: $primary-color;
            color: #EFFEFF;
        }
    }
}

.secondary-button {
    background-color: #EFFEFF;
    border-color: $primary-color;

    .icon {
        background-color: $primary-color;
        color: #EFFEFF;
    }

    &:hover {
        background-color: $primary-color;
        color: #EFFEFF;

        .icon {
            background-color: #EFFEFF;
            color: $primary-color;
        }
    }
}

.hero-section {
    height: calc(100vh - $nav-height);
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    .move-down-icon {
        position: absolute;
        margin: auto;
        bottom: 24px;
        left: 0;
        right: 0;
        display: flex;
        align-items: center;
        justify-content: center;

        .circle {
            height: 85px;
            width: 85px;
            border-radius: 85px;
            animation: rotateCircle linear 10s infinite;
        }

        .icon {
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            color: white;
            height: 52px;
            width: 52px;
            border-radius: 52px;

            background-color: $primary-color;
        }

        @keyframes rotateCircle {
            0% {
                transform: rotate(0deg);
            }

            100% {
                transform: rotate(360deg);
            }
        }
    }

    .pattern-left {
        position: absolute;
        left: 0;
        height: 100vh;
    }

    .pattern-right {
        position: absolute;
        right: 0;
        height: 100vh;
    }

    .container {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .hero-content {
        margin-top: -48px;
        display: grid;
        height: fit-content;
        justify-items: center;
        text-align: center;
        width: 70vw;
        gap: 24px;
        padding: 1vw;
        position: relative;
        z-index: 1;

        .hero-title {
            text-transform: capitalize;
            font-size: 3vw;

            span {
                color: $primary-color;
            }
        }
    }

    .hero-images {
        position: relative;

        img {
            border-radius: 24px;
            width: 20vw;
            object-fit: cover;
        }

        .small {
            position: absolute;
            right: -64px;
            bottom: -64px;
            border: 12px solid white;
            width: 12vw;
        }

        .right {
            right: unset;
            left: -64px;
        }
    }
}

@media screen and (max-width: 1080px) {
    body {
        font-size: 16px;
    }

    .hero-section {
        height: 680px;
        padding-top: 120px;

        .move-down-icon {
            bottom: -80px;
        }

        .pattern-right,
        .pattern-left {
            height: auto;
            width: 50%;
        }

        .container {
            justify-content: space-between;
            gap: 48px;
            justify-content: space-between;

            .hero-content {
                width: 50%;
                text-align: start;
                justify-items: start;

                .hero-title {
                    font-size: 32px;
                }
            }

            .hero-images {
                width: 50%;
                display: flex;
                justify-content: end;

                .small {
                    width: 40%;
                }

                img {
                    width: 70%;
                }

                .right {
                    left: 20px;
                }
            }

            .left-images {
                flex: 1 1 300px;
                display: none;
            }
        }
    }
}


@media screen and (max-width: 780px) {
    .buttons {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
    }

    .hero-section {
        height: auto;

        .pattern-right {
            display: none;
        }

        .move-down-icon {
            display: none;
        }

        .pattern-left {
            width: 100%;
        }

        .container {
            flex-direction: column;
            gap: 62px;
            text-align: center;

            .hero-content {
                width: 100%;
                text-align: center;
                justify-items: center;

                .hero-title {
                    font-size: 22px;
                }
            }

            .hero-images {
                width: 100%;
                margin-top: 50px;

                img {
                    width: 100%;
                    height: 30vh;
                }

                .small {
                    top: -80px;
                    left: 0;
                    right: 0;
                    margin-inline: auto;
                    height: auto;
                }
            }
        }
    }
}