.application-page .container {
  margin: auto;
  display: flex;
  align-items: baseline;
  flex-wrap: wrap;
  gap: 48px;
  padding: 50px 0;
}
.application-page .container .content {
  flex: 1 1 400px;
  display: grid;
  height: -moz-fit-content;
  height: fit-content;
  gap: 24px;
}
.application-page .container .content .title {
  display: flex;
  align-items: center;
  gap: 12px;
  color: #145C74;
}
.application-page .container .content .title .icon {
  height: 46px;
  width: 46px;
  background-color: #EAF9FF;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
}
.application-page .container .content .trainings {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
}
.application-page .container .content .trainings .training {
  border-radius: 12px;
  max-width: 298px;
  padding: 12px;
  background-color: whitesmoke;
  display: flex;
  align-items: start;
  gap: 12px;
}
.application-page .container .content .trainings .training .icon {
  background-color: white;
  height: 46px;
  width: 46px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.application-page .container .content .trainings .training .training-content {
  flex: 1;
}
.application-page .container .content .trainings .training .training-content h4 {
  font-size: 16px;
}
.application-page .container .content .trainings .training .training-content p {
  font-size: 14px;
}
.application-page .container .form {
  flex: 1 1 400px;
}
.application-page .container .form button {
  width: -moz-fit-content;
  width: fit-content;
}

@media screen and (max-width: 768px) {
  .application-page .container .content .trainings .training {
    max-width: 100%;
    flex: 1 1 100px;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  .application-page .container .content .trainings .training .training-content h4 {
    font-size: 12px;
  }
  .application-page .container .content .trainings .training .training-content p {
    display: none;
  }
}
.success-message {
  background-color: #145C74;
  padding: 12px;
  color: white;
  border-radius: 12px;
}/*# sourceMappingURL=applicationPage.css.map */